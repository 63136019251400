<div class="main">
  <div class="row contenido padding-top-10">
    <div class="col-md-6 padding-top-10">
      <h1 class="titulo">
        {{categoriaSeleccionada.nombre}} Incovall
      </h1>
      <div class="menu-trabajos" >
        <a [href]="item.url" [ngClass]="{'activa': item.url == categoriaSeleccionada.url }" *ngFor="let item of categorias" [innerHTML]="item.nombre"></a>
      </div>
    </div>
    <div class="col-md-6" [innerHTML]="categoriaSeleccionada.descripcion" *ngIf="categoriaSeleccionada.descripcion; else noCategoria"></div>
    <ng-template #noCategoria>
      <div class="col-md-6">
        <h3>DISEÑAMOS JUNTOS, CONÓCE NUESTRO TRABAJO</h3>
        Dos décadas fabricando a la medida de tus sueños.
        En Incovall queremos darte a conocer nuestros trabajos y los materiales con los que hacemos tu cocina y baño.
        Te escuchamos, resolvemos tus dudas, te mostramos diferentes acabados... todo para que consigas la cocina y baño ideal.
      </div>
    </ng-template>
  </div>
  <div class="row contenido listado" *ngIf="productosFiltrados.length; else noTrabajos">
    <div class="col-md-6" *ngFor="let item of productosFiltrados">
      <div class="trabajo">
        <div class="item" [ngStyle]="{'background-image': 'url('+ item?.imagenes[0].path + ')'}" routerLink="/trabajo/{{item.url}}">
          <div class="info">
            <h3 [innerHTML]="item.nombre"></h3>
            <p [innerHTML]="getNombreCategoriaProducto(item.id_categoria)"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noTrabajos>
    <div class="contenido">
      <h3>No hay trabajos publicados en este momento</h3>
    </div>
  </ng-template>
</div>
