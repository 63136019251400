import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule,
  MatExpansionModule, MatDatepickerModule, MatInputModule, MatTabsModule,
  MatTooltipModule, MatFormFieldModule, MatMenuModule, MatSnackBarModule, MatSelectModule, MatRadioModule, MatListModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Http, HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import { LocationStrategy, HashLocationStrategy, DatePipe, registerLocaleData } from '@angular/common';
import { AngularFontAwesomeModule } from 'angular-font-awesome';


// Rutas
import { app_routing } from './app.routes';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { TrabajosComponent } from './components/trabajos/trabajos.component';
import { TrabajoComponent } from './components/trabajo/trabajo.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { AvisoComponent } from './components/aviso/aviso.component';

import { SanitizerPipe } from './pipes/sanitizer.pipe';
import { FormatocomasPipe } from './pipes/formatocomas.pipe';
import { ContactoComponent } from './components/contacto/contacto.component';
import { PromocionesComponent } from './components/promociones/promociones.component';
import { CookiesComponent } from './components/cookies/cookies.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    TrabajosComponent,
    TrabajoComponent,
    EmpresaComponent,
    AvisoComponent,
    SanitizerPipe,
    FormatocomasPipe,
    ContactoComponent,
    PromocionesComponent,
    CookiesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // new modules added here
    [MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule, MatMenuModule,
    MatExpansionModule, MatInputModule, MatTabsModule, MatTooltipModule, MatFormFieldModule,
    MatSnackBarModule, MatSelectModule, MatRadioModule, MatListModule],
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    app_routing,
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: function(http: Http) {return new TranslateStaticLoader(http, '/assets/i18n', '.json')},
      deps: [Http]
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
