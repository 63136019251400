import { Component, OnInit, HostListener, Inject, ViewChild } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { Directive, ElementRef,Renderer} from '@angular/core';

import { FormControl, Validators, EmailValidator } from '@angular/forms';
import { ContactForm } from '../../models/contactForm';
//libreria rxjs
import {Observable} from 'rxjs/Observable';
import {Observer} from 'rxjs/Observer';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';

import { of } from 'rxjs/observable/of';

//import { NgForm } from '@angular/forms/src/directives/ng_form';

//services en angular
import {SendEmailService} from '../../services/send-email.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html'
})
export class ContactoComponent implements OnInit {
  vistaApartado:boolean = false;
  apartado: string = "";
  texto: any[];

  public enviado = false;
  mensajeEnvio: string;

  // para el formulario
  email = new FormControl('', [Validators.required, Validators.email]);
  ContactModel = new ContactForm();
  private emailRespose;
  private truefalse:boolean = false;
  aviso:boolean = false;



  constructor(private _datosService:DatosService, private activatedRoute:ActivatedRoute, private router: Router,  private sendServices: SendEmailService) {}


  ngOnInit() {
    this._datosService.ponSeccion('dinamica');
    this.vistaApartado = false;
    this.sacaApartado();

    this._datosService.updateTitle('INCOVALL. Fabricantes de muebles de cocina y baños en Valladolid');
    this._datosService.updateOgUrl('');
    //Updating Description tag dynamically with title
    this._datosService.updateDescription('Incovall. Fabricantes de muebles de cocina y baños. Diseño y exposición, presupuesto sin compromiso. Muebles a medida con o sin instalación.');


  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1500);

  }

  aceptarAviso(event){
    if(this.aviso){ this.aviso = false; } else { this.aviso = true; }
  }

  getErrorMessage(){
    return this.email.hasError('required') ? 'Introduzca un correo electrónico' :
      this.email.hasError('email') ? 'Correo inválido':
        '';
  }
/*
  onSubmit(f: NgForm){
    this.getSentServices(this.ContactModel, f);
  }

  getSentServices(body:ContactForm, f: NgForm){
    this.sendServices.getResponseEmail(body)
      .subscribe(
          data => {
              if(data){

                  this.enviado = true;

                  this.mensajeEnvio = "<h1>Gracias por contactar con nosotros</h1> <br/> EN BREVE NOS PONDREMOS EN CONTACTO CONTIGO";

                  f.reset();
              }
              else{
                this.enviado = true;
                this.mensajeEnvio = "<h2>Ha ocurrido un error de conexión, por favor inténtelo más tarde.</h2>";

              }
          },
          err => {

            console.log(err);
          }
      );
  }
*/
}
