import { Component, OnInit, HostListener, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { DatosService } from '../../services/datos.service';
import { TranslateService } from 'ng2-translate';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  apartadosTodos:any = ['CONOCENOS', 'redondo', 'contacto', 'blog', 'como-comprar'];
  apartadosSecundarios:any = ['aviso-legal', 'politica-de-privacidad', 'politica-de-cookies'];

  constructor(  private router: Router,
    public _datosService:DatosService,
    private translate: TranslateService, private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
  }

}
