import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html'
})
export class EmpresaComponent implements OnInit {

constructor(public _datosService:DatosService, private meta:Meta) { }

  ngOnInit() {
    this._datosService.updateTitle('INCOVALL. Fabricantes de muebles de cocina y baños en Valladolid');
    this._datosService.updateOgUrl('');
    //Updating Description tag dynamically with title
    this._datosService.updateDescription('Incovall. Fabricantes de muebles de cocina y baños. Diseño y exposición, presupuesto sin compromiso. Muebles a medida con o sin instalación.');

  }

}
