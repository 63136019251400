<div class="menu" [ngClass]="{'open': _datosService.menuOpen}">
  <div class="logotipo"  (click)="_datosService.menuOpen = false;" routerLink="/home" *ngIf="!_datosService.menuOpen"><img src="assets/images/logoVertical.png" alt="Incovall logotipo"></div>
  <div class="logotipo"  (click)="_datosService.menuOpen = false;" routerLink="/home" *ngIf="_datosService.menuOpen"><img src="assets/images/logotipo.png" alt="Incovall logotipo"></div>
  <div class="logotipo2"  (click)="_datosService.menuOpen = false;" routerLink="/home" ><img src="assets/images/logotipo.png" alt="Incovall logotipo"></div>
  <div class="icono" [ngClass]="{'open': _datosService.menuOpen}">
    <div class="icomenu hamburguesa" (click)="_datosService.changeMenu()">
      <div id="nav-icon2" [ngClass]="{'open': _datosService.menuOpen}">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      </div>
    </div>
  </div>
  <div class="elmenu" *ngIf="_datosService.menuOpen">
    <div class="listado">
      <div class="bt" (click)="_datosService.menuOpen = false;" routerLinkActive="selected"  routerLink="/conocenos">  <span>CONÓCENOS</span></div>
      <div class="bt" (click)="_datosService.menuOpen = false;" routerLinkActive="selected"  routerLink="/trabajos">  <span>TRABAJOS</span></div>
      <div class="bt-min" (click)="_datosService.menuOpen = false;" routerLinkActive="selected"  routerLink="/cocinas">  <span>COCINAS</span></div>
      <div class="bt-min" (click)="_datosService.menuOpen = false;" routerLinkActive="selected"  routerLink="/banos">  <span>BAÑOS</span></div>
      <div class="bt-min" (click)="_datosService.menuOpen = false;" routerLinkActive="selected"  routerLink="/materiales">  <span>MATERIALES</span></div>
      <div class="bt" (click)="_datosService.menuOpen = false;" routerLinkActive="selected"  routerLink="/promociones">  <span>PROMOCIONES</span></div>
      <div class="bt" (click)="_datosService.menuOpen = false;" routerLinkActive="selected"  routerLink="/contacto">  <span>CONTACTO</span></div>
      <br>
      <div class="bt bt-mini" (click)="_datosService.menuOpen = false;" routerLinkActive="selected"  routerLink="/{{item}}" *ngFor="let item of apartadosSecundarios">
        <span [innerHTML]=" item | translate"></span>
      </div>
    </div>
  </div>
  <div class="redes" [ngClass]="{'open': _datosService.menuOpen}">
    <a href="https://www.facebook.com/CocinasIncovall/" target="_blank"><fa class="ico" name="facebook" ></fa></a>
    <fa class="ico" name="instagram" ></fa>
    <fa class="ico" name="youtube"></fa>
    <a class="tel" href="tel:983304611">983 304 611</a>
  </div>


</div>
