<div class="main">
  <div class="row contenido padding-top-10 listado">
    <div class="titular">
      <h2 class="subtitulo">Incovall</h2>
      <h1 class="titulo" [innerHTML]="rutaApartado | translate"></h1>
    </div>
    <div class="margin-bottom-10 t-legal" [ngClass]="{'open': vistaApartado}">
      <p class="text-left t-legal" [innerHTML]="texto" *ngIf="_datosService.getIdioma()== 'es'"></p>
      <p class="text-left t-legal" [innerHTML]="texto_en"  *ngIf="_datosService.getIdioma()== 'en'"></p>
    </div>
  </div>
</div>
