<div class="main" [ngClass]="{'open': vistaApartado}">
  <div class="contenido">
    <div *ngIf="isNoticia">
      <section class="noticia" >
          <div class="info">
            <div class="dato text-left"><span class="fecha">{{ devuelveFecha(noticia['publishing_date']) | date: 'dd/MM/yyyy' }}</span></div>
              <h1 class="titulo" [innerHtml]="noticia['title']"></h1>
              <img class="principal" [src]="noticia['imagenes'][0].path" alt="">
              <div class="text-justify" [innerHtml]="noticia['intro']"></div>
              <hr>
              <div class="text-justify" [innerHtml]="noticia['content']"></div>
              <div class="bt-tipo" routerLink="/promociones" > <span>VOLVER</span> </div>
          </div>
      </section>
    </div>
    <div *ngIf="!isNoticia">
      <div class="noticias row">
        <div class="col-md-6">
          <h1 class="titulo">PROMOCIONES INCOVALL</h1>
        </div>
        <div class="listado row" >
          <div class="col-md-6" *ngFor="let item of noticiasFiltradas" >
            <div class="item" routerLink="/promociones/{{item['url']}}"  >
              <div class="foto" [ngStyle]="{'background-image': 'url('+ item['imagenes'][0].path + ')'}" ></div>

              <div class="info">
                <h3 class="titulo" [innerHtml]="item['title']"></h3>
                <div class="dato">
                  <span class="fecha">{{ devuelveFecha(item['publishing_date']) | date: 'dd/MM/yyyy' }}</span>
                </div>
                <div class="intro" [innerHtml]="item['intro']"></div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
