import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { DatosService } from '../../services/datos.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  active: boolean = false;
  fotos:any = ['slide0.jpg', 'slide1.jpg', 'slide2.jpg'];

  fotoActual: any = '';
  espera: number = 6000;
  indexActual:number;
  interval;

  ofertas:any = [];
  oferta:any = [];

  vistaApartado:boolean = false;

  constructor(public _datosService:DatosService, private meta:Meta) { }

  ngOnInit() {
    this.indexActual = 0;
    this.vistaApartado = false;
    this.oferta = [];
    this.sacaApartado();
    setTimeout(() => { this.active = true; this.getNext(); }, 500);

    this._datosService.updateTitle('INCOVALL. Fabricantes de muebles de cocina y baños en Valladolid');
    this._datosService.updateOgUrl('');
    //Updating Description tag dynamically with title
    this._datosService.updateDescription('Incovall. Fabricantes de muebles de cocina y baños. Diseño y exposición, presupuesto sin compromiso. Muebles a medida con o sin instalación.');


    //Cargamos las categorias...
    this._datosService.getNoticias().subscribe(datos => {
      this.ofertas = datos;
      this.ofertas = this.ofertas.filter((oferta)=> oferta.publicado );
      this.oferta = this.ofertas[Math.floor(Math.random()*this.ofertas.length)];
      console.log(this.oferta);
    });
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1000);
  }

  //FUNCIONES DE SLIDE DE HOME
getNext() {
  this.interval = setInterval (() => {
      if(this.indexActual == this.fotos.length - 1) {
        this.indexActual = 0;
      }
      else {
        this.indexActual++;
      }
  }, this.espera);
}

}
