import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-trabajos',
  templateUrl: './trabajos.component.html'
})
export class TrabajosComponent implements OnInit {

  rutaCategoria:string = ''; //URL CATEGORIA PADRE
  rutaCategoria2:string = '';  //URL CATEGORIA HIJA
  apartados:any = ['cocinas', 'banos', 'materiales'];
  productos:any = [];
  productosFiltrados:any = [];
  categorias:any = []; //TODAS LAS CATEGORIAS
  categoriaSeleccionada:any = []; //CATEGORIA PADRE

  constructor(
    private activatedRoute:ActivatedRoute,
    private router: Router,
    public _datosService:DatosService) {
    }

  ngOnInit() {
    this.activatedRoute.url.subscribe( url=> {
      this.rutaCategoria = this.activatedRoute.snapshot.url[0].path;
      //Cargamos las categorias...
      this._datosService.getCategorias().subscribe(datos => {
        this.categorias = datos;
        console.log(this.categorias);
        //ORDENO POR POSITION
        this.categorias.sort((val1, val2)=> {return val2.position - val1.position});
        this.categoriaSeleccionada = [];

        if(this.rutaCategoria != 'trabajos') {
          let indiceCategoria = this.categorias.findIndex((indiceCategoria) => indiceCategoria.url == this.rutaCategoria );
          this.categoriaSeleccionada = this.categorias[indiceCategoria];
        }
        else {

        }
        this.getProductos();
      });

    });
  }

  getProductos() {
    this._datosService.getProductos().subscribe(datos => {
      this.productos = datos;
      console.log(this.productos);
      //FILTRO POR CATEGORÍA SI ESTÁ SELECCIONADA LA HIJA
      if(this.categoriaSeleccionada['id']) {
          this._datosService.updateTitle(this.categoriaSeleccionada['meta_titulo'] + ' | Incovall');
          this._datosService.updateOgUrl(this.rutaCategoria);
          //Updating Description tag dynamically with title
          this._datosService.updateDescription(this.categoriaSeleccionada['meta_descripcion']);
          this.productosFiltrados =  this.productos.filter((producto)=> producto.id_categoria == this.categoriaSeleccionada['id'] );

        }
        else {
          this.productosFiltrados = this.productos;
          this._datosService.updateTitle('TRABAJOS INCOVALL. Fabricantes de muebles de cocina y baños en Valladolid');
          this._datosService.updateOgUrl('');
          //Updating Description tag dynamically with title
          this._datosService.updateDescription('Incovall. Fabricantes de muebles de cocina y baños. Diseño y exposición, presupuesto sin compromiso. Muebles a medida con o sin instalación.');

        }

        this.productosFiltrados = this.productosFiltrados.filter((producto)=> producto.publicado );
        //ORDENO POR POSITION
        this.productosFiltrados.sort((val1, val2)=> {return val2.position - val1.position});
    });

  }
  getNombreCategoriaProducto(idCategoria) {
    let categoria = this.categorias.find((cat) => cat.id === idCategoria);
    let campoidioma = 'nombre';
    return categoria[campoidioma];
  }

}
