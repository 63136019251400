import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { DatosService } from '../../services/datos.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Location } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from 'ng2-translate';

@Component({
  selector: 'app-trabajo',
  templateUrl: './trabajo.component.html'
})
export class TrabajoComponent implements OnInit {

  rutaProducto:string = '';
  categorias:any = [];
  producto:any = [];
  productoFoto:string = '';
  productoIdCategoria:string = '';
  productos:any = [];
  nombreProducto: string;
  descripcionProducto: string;

  productosFiltrados:any = [];
  productoIndice:number;
  productoAnterior: any[];
  productoSiguiente: any[];


  vistaApartado:boolean = false;

  video:string = "";
  trustedDashboardUrl:any;

  galeria:any[];
  fotos:any = [];
  fotoActual: any = '';
  espera: number = 6000;
  indexActual:number;
  interval;
  active: boolean = false;


  constructor(
    private activatedRoute:ActivatedRoute,
    private router: Router,
    public _datosService:DatosService,
    private location: Location,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private meta:Meta
  ) { }

  ngOnInit() {
    this._datosService.ponSeccion('producto');

    this.activatedRoute.url.subscribe( url=> {
        if(this.activatedRoute.snapshot.url.length > 1) {
          this.rutaProducto = this.activatedRoute.snapshot.url[1].path;
        }

        //Cargamos las categorias...
        this._datosService.getCategorias().subscribe(datos => {
          this.categorias = datos;

          this._datosService.getProducto(this.rutaProducto).subscribe(datos => {
            this.producto = datos;

            //saco del objeto galeria las entradas
            let galeria = this.producto['galeria'].map((foto) => foto.imagenes);
            let arrGaleria = Object.keys(galeria).map(function(foto){
              let laFoto = galeria[foto][0];
              return laFoto;
            });

            //concateno array imagenes con array galeria ya limpio
            this.galeria = this.producto['imagenes'].concat(arrGaleria);
            this.galeria = this.galeria.map((foto) => foto.path );
            this.indexActual = 0;
            setTimeout(() => { this.active = true; this.getNext(); }, 500);


            this.productoIdCategoria = this.producto.id_categoria;
            this.nombreProducto = this.producto['nombre'];
            this.descripcionProducto = this.producto['descripcion'];

            let miVideo = "https://www.youtube.com/embed/" + this.producto['etiqueta'];
            this.trustedDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(miVideo);
            this.video = this.trustedDashboardUrl;


            this._datosService.updateTitle('Incovall | ' + this.producto['meta_titulo']);
            this._datosService.updateOgUrl(this.rutaProducto);
            //Updating Description tag dynamically with title
            this._datosService.updateDescription(this.producto['meta_descripcion'])


            this.sacaApartado();
            //Cargamos los productos de igual categoria ...
            this._datosService.getProductosFiltrados(this.producto['id_categoria']).subscribe(datos => {
              this.productosFiltrados = datos;

              //saber el indice del proyecto actual...
              this.productoIndice = this.productosFiltrados.findIndex(item=> item['id'] === this.producto['id']);
              console.log(this.productoIndice);
              if(this.productosFiltrados.length > 1) {
                if(this.productoIndice == 0) {
                    this.productoAnterior = this.productosFiltrados[this.productosFiltrados.length - 1];
                }
                else {
                  this.productoAnterior = this.productosFiltrados[this.productoIndice - 1];
                }

                if(this.productoIndice == this.productosFiltrados.length - 1) {
                    this.productoSiguiente = this.productosFiltrados[0];
                }
                else {
                  this.productoSiguiente = this.productosFiltrados[this.productoIndice + 1];
                }

              }
            });





          });

        });
      }
    );
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 500);
  }




  getNombreCategoriaProducto(idCategoria) {
    let categoria = this.categorias.find((cat) => cat.id === idCategoria);
    let campoidioma = 'nombre';
    campoidioma = "nombre_" + this._datosService.selectedIdioma;

    return categoria[campoidioma];
  }


  volver() {
    this.location.back();
  }

  getNext() {
    this.interval = setInterval (() => {
        if(this.indexActual == this.galeria.length - 1) {
          this.indexActual = 0;
        }
        else {
          this.indexActual++;
        }
        //this.foto = this.trabajo['imagenes'][this.indexActual];
    }, this.espera);
  }

  hayFotos() {
    if(this.galeria.length > 2) {
      return true;
    }
  }

  ponFoto(esta) {
    clearInterval(this.interval);
    this.indexActual = esta;
    //setTimeout(() => { this.active = true; this.getNext(); }, 500);
  }

  verProyecto(url) {
  //this.apartadoAbierto = false;
    clearInterval(this.interval);
    this.router.navigate( ['/trabajo', url]);
  }


}
