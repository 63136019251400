import { Component, OnInit, HostListener, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TranslateService } from 'ng2-translate';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})

export class DatosService {
  public urlApi:string = 'https://incovall.en-pruebas.com/api/'
  public urlNoti:string = 'https://incovall.en-pruebas.com/'

  public isCookies:boolean = false;
  public seccion:string;

  public menuOpen:boolean = false;
  public vistaCarrito:boolean = false;
  public vistaSubmenu:boolean = false;

  public arrDestacados:any = ['producto1', 'producto2', 'producto3', 'producto4', 'producto5', 'producto6', 'producto7', 'producto8'];
  destacadoActual: any = '';
  espera: number = 7000;
  indexActual:number;
  interval;

  public selectedIdioma:string = '';
  public globalScroll: boolean = true;

  public datosProducto: any;
  public categoriaBlog:any = '';


  constructor(
    public http: HttpClient,
    private router:Router,
    private translate: TranslateService,
    private location: Location,
    private title: Title,
    private meta: Meta) {
      if(localStorage.getItem("isCookies") == "true"){
        this.isCookies = true;
      }
    }

    //PARA METAS

    updateTitle(title: string) {
      this.title.setTitle(title);
    }

    updateOgUrl(url: string) {
      this.meta.updateTag({ name: 'og:url', content: url })
    }

    updateDescription(desc: string) {
      this.meta.updateTag({ name: 'description', content: desc })
    }


    aceptaCookies() {
      this.isCookies = true;
      localStorage.setItem("isCookies", "true");
    }

    changeMenu() {
      this.menuOpen =! this.menuOpen;
    }

    ponSeccion(esta) {
      this.seccion = esta;
    }

    isSeccionFija() {
      if(this.seccion == 'login') {
        return true;
      }
      else {
        return false;
      }
    }


    //noticias
    cambiaVistaSubmenu() {
      this.vistaSubmenu = !this.vistaSubmenu;
    }



    //PRODUCTOS..
    getProductos( ) {
      let url:string = this.urlApi +`productos/`;
      let headers = new HttpHeaders({
      })
      return this.http.get(url, { headers: headers })
      .map((resp:any) =>{
        return resp;
      });
    }

    getProductosFiltrados(miCategoria ) {
      let url:string = this.urlApi +`productos/`;
      let headers = new HttpHeaders({
      })
      return this.http.get(url, { headers: headers })
      .map((resp:any) =>{

        let datos =  resp.filter((producto) => producto.id_categoria === miCategoria);
        return datos;
      });
    }

    getProducto( producto ) {
      let url:string = this.urlApi +`productos/` + producto;
      let headers = new HttpHeaders({
      })
      return this.http.get(url, { headers: headers })
      .map((resp:any) =>{
        return resp;
      });
    }

    getCategorias( ) {
      let url:string = this.urlApi +`productos/categorias/`;
      let headers = new HttpHeaders({
      })
      return this.http.get(url, { headers: headers })
      .map((resp:any) =>{
        return resp;
      });
    }

    //RECTAS

    getReceta( receta ) {
        let url:string = this.urlApi +`recetas/` + receta;
        let headers = new HttpHeaders({
        })
        return this.http.get(url, { headers: headers })
        .map((resp:any) =>{
          return resp;
        });
      }

    getRecetas( ) {
      let url:string = this.urlApi +`recetas/`;
      let headers = new HttpHeaders({
      })
      return this.http.get(url, { headers: headers })
      .map((resp:any) =>{
        return resp;
      });
    }

    getCategoriasRecetas( ) {
      let url:string = this.urlApi +`recetas/categorias/`;
      let headers = new HttpHeaders({
      })
      return this.http.get(url, { headers: headers })
      .map((resp:any) =>{
        return resp;
      });
    }

    getTagsRecetas( ) {
      let url:string = this.urlApi +`recetas/tags/`;
      let headers = new HttpHeaders({
      })
      return this.http.get(url, { headers: headers })
      .map((resp:any) =>{
        return resp;
      });
    }

    //FUCIONES DE CARRITO
    cambiaVistaCarrito() {
      this.vistaCarrito = !this.vistaCarrito;
    }

    getPaises( ) {
      let url:string = this.urlApi +`portes/paises`;
      let headers = new HttpHeaders({
      })
      return this.http.get(url, { headers: headers })
       .map((resp:any) =>{
           return resp;
       });
      }


      getIdioma() {
       if(this.selectedIdioma == '') {
         this.selectedIdioma = this.translate.getBrowserLang();
       }
       return this.selectedIdioma;
     }


     getCampo(campo) {
       if(this.selectedIdioma == '') {
         this.selectedIdioma = this.translate.getBrowserLang();
       }
       let campoidioma = campo;
       if(this.selectedIdioma != 'es') {
         campoidioma = campo + "_" + this.selectedIdioma;
       }
       return campoidioma;
     }


    getTexto(clave ) {
      let url:string = this.urlApi + `preferencias/` + clave;
      let headers = new HttpHeaders({
      })
      return this.http.get(url, { headers: headers })
       .map((resp:any) =>{
           return resp;
       });
      }


     createSlug(str){
       str = str.replace(/^\s+|\s+$/g, ''); // trim
       str = str.toLowerCase();

       // remove accents, swap ñ for n, etc
       var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
       var to   = "aaaaeeeeiiiioooouuuunc------";
       for (var i=0, l=from.length ; i<l ; i++) {
           str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
       }

       str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
           .replace(/\s+/g, '-') // collapse whitespace and replace by -
           .replace(/-+/g, '-'); // collapse dashes

       return str;
   }

   volver() {
     this.location.back();
   }


   //Blog
getCategoriasNoticias( ) {
let url:string = this.urlApi +`noticias/categorias/`;
let headers = new HttpHeaders({
})
return this.http.get(url, { headers: headers })
 .map((resp:any) =>{
     return resp;
 });
}

getNoticias() {
 let url:string = this.urlApi +`noticias/`;
 let headers = new HttpHeaders({
 })
 return this.http.get(url, { headers: headers })
  .map((resp:any) =>{
      return resp;
  });
}

getNoticia( laNoticia ) {
  let url:string = this.urlApi +`noticias/` + laNoticia;
  let headers = new HttpHeaders({
  })
  return this.http.get(url, { headers: headers })
   .map((resp:any) =>{
       this.datosProducto = resp;
       return this.datosProducto;
   });
}


filtraCategoriaBlog(idCategoria) {
  this.categoriaBlog = idCategoria;
}


limpiaFiltros() {
  this.categoriaBlog = '';
}

}
