<div class="main">
  <div class="row contenido padding-top-10 listado conocenos">
    <div class="col-md-6">
      <h1 class="titulo">Profesionalidad y experiencia</h1>
      <h3>Máxima personalización</h3>
      <p>En INCOVALL nos adaptamos a tí, a tus gustos, necesidades y expectativas. Porque fabricamos a medida.</p>
      <h3>Distribución y orden</h3>
      <p>El orden es fundamental en tu cocina, para ello el equipo de diseñadores de INCOVALL te escucha e innova para ofrecerte soluciones. Aprovechamos al milímetro el espacio de tu cocina, para que no eches en falta nada.</p>
      <h3>Diseño y composición</h3>
      <p>Somos especialistas en la fusión de estilos, materiales y formas. Jugamos con la luz, la disposición y los elementos de que dispones para sacar el máximo partido a cada rincón.</p>
      <a href="/trabajos" class="bt-tipo">
        <fa name="arrow-right"></fa> Ver nuestros <span>trabajos</span>
      </a>
    </div>
    <div class="col-md-6">
      <div class="foto"><img src="assets/images/diseno.jpg" alt=""></div>

    </div>
    <div class="col-md-6 padding-top-10">
      <h2 class="titulo">Nuestro método de trabajo</h2>
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-4">
      <div class="ico"><img src="assets/images/iconos/bienvenido.png" alt=""></div>
      <h3>Bienvenido</h3>
      <p>Vente cuando quieras a visitarnos, nuestra exposición está abierta y esperándote. <br> Aquí te mostraremos colores, diseños, materiales, acabados... y un mundo de posibilidades.</p>
    </div>
    <div class="col-md-4">
      <div class="ico"><img src="assets/images/iconos/escucha.png" alt=""></div>
      <h3>Te escuchamos</h3>
      <p>Cuéntanos tus ideas, tus gustos, el estílo de tu hogar, los hábitos de tu familia... juntos perfilaremos el tipo de cocina que mejor se adapte.</p>
    </div>
    <div class="col-md-4">
      <div class="ico"><img src="assets/images/iconos/sentamos.png" alt=""></div>
      <h3>Nos sentamos contigo</h3>
      <p>Lo idóneo es que traigas un plan do de tu cocina, medidas, ventanas, puntos de luz, radiadores, enchufes... todo esto es importante para empezar a diseñar.</p>
    </div>
    <div class="col-md-4">
      <div class="ico"><img src="assets/images/iconos/3d.png" alt=""></div>
      <h3>Tu diseño 3D</h3>
      <p>Con nuestro programa de diseño comenzaremos a perfilar tu cocina, armarios, electrodomésticos, barras de desayuno, islas... así obtendremos un boceto inicial.
      <br><br>Desde tu boceto aplicaremos diferentes acabados, colores y elementos para hacer una simulación en tres dimensiones de tu cocina.</p>
    </div>
    <div class="col-md-4">
      <div class="ico"><img src="assets/images/iconos/presupuesto.png" alt=""></div>
      <h3>Te damos presupuesto</h3>
      <p>Una vez definida tu cocina hacemos un presupuesto sin compromiso especificándote cada cosa. Recuerda que somos fabricantes y trabajamos con los mejores proveedores de electrodomésticos por lo que obtendrás estupendos descuentos.</p>
    </div>
    <div class="col-md-4">
      <div class="ico"><img src="assets/images/iconos/medidas.png" alt=""></div>
      <h3>Tomamos medidas</h3>
      <p>Cuando aceptes el presupuesto iremos a tu casa para tomar medidas exactas de la estancia, puntos de luz, tomas de corriente etc...
        <br><br>Con todos estos datos comenzaremos el proceso de fabricación de tu cocina, una vez esté terminada nuestro equipo de montaje irá a instalarla.
      </p>
    </div>
    <div class="claim">
      ¡Empieza a disfrutar de TU COCINA INCOVALL!
    </div>
  </div>


</div>
