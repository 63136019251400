<div class="main">
  <div class="fotoHome">
    <div class="slide text-left">
      <div class="fotos">
        <div  *ngFor="let foto of fotos; let i = index" [ngClass]="['foto', i === indexActual ? 'activa' : '']" [ngStyle]="{'background-image': 'url(assets/images/home/' + foto +')'}" ></div>
      </div>
    </div>
    <div class="icono">
      <fa class="ico" name="arrow-down" ></fa>
      scroll
    </div>
    <div class="oferta" *ngIf="oferta" (click)="oferta = false;" >
      <p class="texto" [innerHTML]="oferta.intro"></p>
      <div class="btn" routerLink="/promociones/{{oferta.url}}">VER OFERTA</div>
      <div class="cerrar"><fa name="times"></fa></div>
    </div>
  </div>
  <div class="contenidoHome">
    <section class="row contenido">
      <div class="col-md-6">
        <h1 class="titulo">
          Diseñamos, fabricamos y montamos la cocina de tus sueños.
        </h1>
        <a href="/cocinas" class="bt-tipo">
          <fa name="arrow-right"></fa> Ver nuestras <span>cocinas</span>
        </a>

      </div>
      <div class="col-md-6">
        <p>
          En INCOVALL llevamos desde 1995 fabricando a medida cocinas de calidad con acabados cuidados y materiales resistentes.
          Siempre adaptados a las nuevas tendencias y a tus necesidades específicas.
        </p>
        <p>
          Te esperamos en nuestra exposición en dónde encontrarás multitud de posibilidades par tu cocina y cuarto de baño, con la confianza de ser fabricantes y adaptados a tu presupuesto.
        </p>
      </div>
      <!--
      <div class="col-12 foto fix" [ngStyle]="{'background-image': 'url(assets/images/home/cocinas.jpg)'}"></div>
      -->
      <div class="col-12 foto">
        <img src="assets/images/home/cocinas.jpg" alt="">
      </div>
    </section>
    <section class="row contenido">
      <div class="col-md-6">
        <h1 class="titulo">
          Más que un baño un espacio de relax y cuidado.
        </h1>
        <a href="/banos" class="bt-tipo">
          <fa name="arrow-right"></fa> Ver nuestros <span>baños</span>
        </a>

      </div>
      <div class="col-md-6">
        <p>
          El baño, junto a la cocina, es uno de los espacios que más se valoran en una vivienda.
          En los últimos años ambos han sufrido una revolución, pasando de ser lugares meramente funcionales a diseñarse pensando en el bienestar personal.
        </p>
        <p>
          En INCOVALL te ayudamos a dar forma a tus ideas. Materiales resistentes, diseños luminosos y la ergonomía en el centro.
          Acércate a nuestra exposición y déjate asesorar por nuestros expertos.
        </p>
      </div>
      <div class="col-12 foto">
        <img src="assets/images/home/banos.jpg" alt="">
      </div>
    </section>
    <section class="row contenido">
      <div class="col-md-6">
        <h1 class="titulo">
          Resistentes, bonitos y para todos los presupuestos.
        </h1>
        <a href="/materiales" class="bt-tipo">
          <fa name="arrow-right"></fa> Ver nuestros <span>materiales</span>
        </a>

      </div>
      <div class="col-md-6">
        <p>
          Un amplio catálogo de materiales que facilitan su cuidado, limpieza y mantenimiento.
          Nuevas formas, colores, acabados... para crear la cocina y el baño de tus sueños.
        </p>
        <p>
          Ven a INCOVALL y conoce todas las novedades, en cuanto a materiales y diseños.
          Te esperamos en nuestra exposición en la calle Estaño 3 en el Polígono de San Cristobal.
        </p>
      </div>
      <div class="col-12 foto">
        <img src="assets/images/home/materiales.jpg" alt="">
      </div>
    </section>
  </div>
</div>
