<div class="main">
  <div class="row contenido padding-top-10 listado conocenos">
    <div class="col-md-6">
      <h1 class="titulo">Visita nuestra exposición, te esperamos</h1>
      <h3>Calle Estaño 3, 47012 Valladolid</h3>
      <p>Abierto sábados hasta las 14:00h</p>
    </div>
    <div class="fotoCabecera col-md-12">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23862.03717717262!2d-4.703178!3d41.617817!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4713192c59acef%3A0xe94bb114d051dcb1!2sIncovall!5e0!3m2!1ses!2ses!4v1571645780648!5m2!1ses!2ses" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
    </div>
    <div class="col-md-6">
      <h3 class="titulo2">Contacta con nosotros</h3>
      <p>Para cualquier duda o sugerencia puedes contactar con nosotros de lunes a sábado en el teléfono <a class="enlace" href="tel:983304611">983 304 611</a> o escribiéndonos un email a <a class="enlace" href="mailto:incovall@incovall.es?subject=contacto">incovall@incovall.es</a></p>
    </div>
  </div>
</div>
