<div class="main">
  <div class="contenido contenido-trabajo padding-top-10">
    <div class="menu-trabajos" >
      <a [href]="item.url" *ngFor="let item of categorias" [innerHTML]="item.nombre"></a>
    </div>
    <div class="slide-trabajo">
      <div class="fotos">
          <div *ngFor="let foto of galeria; let i = index" [ngClass]="['lafoto', i === indexActual ? 'activa' : '']" [ngStyle]="{'background-image': 'url(' + foto +')'}" ></div>
      </div>
      <div class="menu-slide">
        <div  *ngFor="let foto of galeria; let i = index"  (click)="ponFoto(i);" [ngClass]="['item', i === indexActual ? 'activa' : '']" ></div>
      </div>
    </div>
      <h1 class="titulo descripcion" [innerHTML]="producto[_datosService.getCampo('nombre')]"></h1>
    <div class="descripcion" [innerHTML]="producto[_datosService.getCampo('descripcion')]"></div>
    <div class="descripcion video" *ngIf="producto.etiqueta" >
      <iframe width="560" height="315" [src]="video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>


    <div class="d-flex flex-row justify-content-between my-flex-container nav-trabajos">
      <div class="p-2 my-flex-item">
        <span class="bt"  *ngIf="producto['url'] != productoAnterior['url']" (click)="verProyecto(productoAnterior['url']);" [innerHtml]="productoAnterior['nombre']"></span>
      </div>
      <div class="p-2 my-flex-item">
          <span class="bt" routerLink="/trabajos" > VER TODO</span>
      </div>
      <div class="p-2 my-flex-item">
          <span class="bt"  *ngIf="producto['url'] != productoSiguiente['url']" (click)="verProyecto(productoSiguiente['url']);" [innerHtml]="productoSiguiente['nombre']"></span>
      </div>
    </div>
  </div>
</div>
